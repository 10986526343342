import get from '../../entry/publicMethods/get/get';
import { getPublicPropertiesStore } from '../../entry/publicMethods/publicProperties/registerPublicProperties';
import { ExpressedConsent } from '../types/consent';
import { FeatureFlags } from '../types/dynamicConfig';
import debounce from '../utils/debounce';

export type DraggablePosition = {
  top: string;
  right: string;
  bottom: string;
  left: string;
} | null;

export const draggable = () => {
  let element: HTMLElement | null;
  let posX = 0;
  let posY = 0;
  let mouseX = 0;
  let mouseY = 0;
  let isMouseDown = false;
  let isDragging = false;
  let isDraggableEnabled = false;
  let inMemoryPosition: DraggablePosition;
  let isOutOfViewport = false;
  let outOfViewportPosition: {
    top: boolean;
    right: boolean;
    bottom: boolean;
    left: boolean;
  };
  let intersectionObserver: IntersectionObserver;

  const setElement = (newElement: HTMLElement | null) => {
    element = newElement;
  };

  const getIsDragging = () => isDraggableEnabled && isDragging;

  const getCookieConsent = (): ExpressedConsent =>
    get('visitor:cookiesConsent') as ExpressedConsent;

  const getInMemoryElementPosition = () => inMemoryPosition;

  const setInMemoryElementPosition = (position: DraggablePosition) => {
    inMemoryPosition = position;
  };

  const getElementPosition = (): DraggablePosition | null => {
    const savedPosition = sessionStorage.getItem('draggable:position');
    return savedPosition
      ? JSON.parse(savedPosition)
      : getInMemoryElementPosition();
  };

  const saveElementPosition = (position: DraggablePosition) => {
    setInMemoryElementPosition(position);
    const cookieConsent = getCookieConsent();
    if (cookieConsent) {
      sessionStorage.setItem('draggable:position', JSON.stringify(position));
    }
  };

  const getIsDraggableEnabled = () => isDraggableEnabled;

  /*___________________________________________________________________________________________________________________________________________________________________*/
  const setIsDraggableEnabled = (
    notificationId: string | undefined,
    featureFlags: FeatureFlags,
  ) => {
    const draggableFlag =
      featureFlags['iadvize.conversations.livechat.notification.draggable'];

    if (draggableFlag && notificationId) {
      const enabledNotificationIds = draggableFlag.split(';');
      isDraggableEnabled = enabledNotificationIds.some(
        (id) => id === notificationId || id === 'true',
      );
    }
  };

  const fixOutViewportPosition = () => {
    if (!outOfViewportPosition || !element) {
      return;
    }
    const animationDuration = 100;
    if (outOfViewportPosition.top) {
      element.style.top = '0';
      element.style.bottom = 'auto';
      element.style.transition = `top ${animationDuration}ms ease-out`;
    }
    if (outOfViewportPosition.bottom) {
      element.style.bottom = '0';
      element.style.top = 'auto';
      element.style.transition = `bottom ${animationDuration}ms ease-out`;
    }
    if (outOfViewportPosition.left) {
      element.style.left = '0';
      element.style.right = 'auto';
      element.style.transition = `left ${animationDuration}ms ease-out`;
    }
    if (outOfViewportPosition.right) {
      element.style.right = '0';
      element.style.left = 'auto';
      element.style.transition = `right ${animationDuration}ms ease-out`;
    }
    setTimeout(() => {
      if (element) {
        element.style.transition = 'none';
      }
    }, animationDuration);
  };

  /*____________________________________________________________________________________________________________________________________________________*/
  const getOpenedChatboxPosition = () => {
    const halfScreenWidth = window.innerWidth / 2;
    const position = getElementPosition();
    return position && parseInt(position.left, 10) < halfScreenWidth
      ? 'left'
      : 'right';
  };

  const onScreenResize = debounce(() => {
    if (element) {
      if (isOutOfViewport) {
        fixOutViewportPosition();
      }
      saveElementPosition({
        top: element.style.top,
        right: element.style.right,
        bottom: element.style.bottom,
        left: element.style.left,
      });
    }
  }, 100);

  const isIframe = (
    testElement: HTMLElement,
  ): testElement is HTMLIFrameElement => testElement.tagName === 'IFRAME';

  const onMouseDown = (e: MouseEvent) => {
    isMouseDown = true;
    posX = e.clientX;
    posY = e.clientY;
  };

  const onTouchStart = (e: TouchEvent) => {
    posX = e.touches[0].clientX;
    posY = e.touches[0].clientY;
  };

  const onMouseMove = (e: MouseEvent) => {
    if (!element) {
      return;
    }
    mouseX = e.clientX + element.offsetLeft;
    mouseY = e.clientY + element.offsetTop;
    if (isMouseDown) {
      isDragging = true;
      element.style.left = `${mouseX - posX}px`;
      element.style.top = `${mouseY - posY}px`;
      element.style.bottom = 'auto';
      element.style.right = 'auto';
      element.setAttribute('aria-grabbed', 'true');
    }
  };

  const onTouchMove = (e: TouchEvent) => {
    e.preventDefault();
    if (element) {
      isDragging = true;
      mouseX = e.touches[0].clientX + element.offsetLeft;
      mouseY = e.touches[0].clientY + element.offsetTop;
      element.style.left = `${mouseX - posX}px`;
      element.style.top = `${mouseY - posY}px`;
      element.style.bottom = 'auto';
      element.style.right = 'auto';
      element.setAttribute('aria-grabbed', 'true');
    }
  };

  const endDraggable = () => {
    isMouseDown = false;
    if (isDragging) {
      //______________________________________________________________________
      setTimeout(() => {
        isDragging = false;
        if (isOutOfViewport) {
          fixOutViewportPosition();
        }
        if (element) {
          element.setAttribute('aria-grabbed', 'false');
          saveElementPosition({
            top: element.style.top,
            right: element.style.right,
            bottom: element.style.bottom,
            left: element.style.left,
          });
        }
      }, 100);
    }
  };

  const removeDraggable = (elementToRemove: HTMLElement | null) => {
    if (elementToRemove && isIframe(elementToRemove)) {
      window.removeEventListener('resize', onScreenResize);
      //___________________
      elementToRemove.contentWindow?.removeEventListener(
        'mousemove',
        onMouseMove,
      );
      elementToRemove.contentWindow?.removeEventListener(
        'mousedown',
        onMouseDown,
      );
      elementToRemove.contentWindow?.removeEventListener(
        'mouseup',
        endDraggable,
      );

      //__________________
      elementToRemove.contentWindow?.removeEventListener(
        'touchstart',
        onTouchStart,
      );
      elementToRemove.contentWindow?.removeEventListener(
        'touchmove',
        onTouchMove,
      );
      elementToRemove.contentWindow?.removeEventListener(
        'touchend',
        endDraggable,
      );
    }
    if (intersectionObserver) {
      intersectionObserver.disconnect();
    }
  };

  const initDraggable = async (
    newElement: HTMLElement | null,
    isMobile: boolean,
  ) => {
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      //____________________________________________________________
      await import('drag-drop-touch');
    }
    setElement(newElement);
    removeDraggable(element);

    if (element && isIframe(element)) {
      element.setAttribute('draggable', 'true');
      if (!isMobile) {
        window.addEventListener('resize', onScreenResize);
      }
      //___________________
      element.contentWindow?.addEventListener('mousedown', onMouseDown);
      element.contentWindow?.addEventListener('mousemove', onMouseMove);
      element.contentWindow?.addEventListener('mouseup', endDraggable);

      //__________________
      element.contentWindow?.addEventListener('touchstart', onTouchStart);
      element.contentWindow?.addEventListener('touchmove', onTouchMove, {
        passive: false,
      });
      element.contentWindow?.addEventListener('touchend', endDraggable);

      getPublicPropertiesStore().on('visitor:cookiesConsent', (consent) => {
        if (consent === false) sessionStorage.removeItem('draggable:position');
      });

      //_____________________________
      const position = getElementPosition();
      if (position) {
        element.style.top = position.top;
        element.style.right = position.right;
        element.style.bottom = position.bottom;
        element.style.left = position.left;
      }

      //_________________________________________________
      intersectionObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach(
            ({ isIntersecting, rootBounds, boundingClientRect }) => {
              isOutOfViewport = !isIntersecting;
              if (rootBounds) {
                outOfViewportPosition = {
                  top: boundingClientRect.top < rootBounds.top,
                  right: boundingClientRect.right > rootBounds.right,
                  bottom: boundingClientRect.bottom > rootBounds.bottom,
                  left: boundingClientRect.left < rootBounds.left,
                };
              }
            },
          );
        },
        {
          threshold: 0.7,
        },
      );

      intersectionObserver.observe(element);
    }
  };

  return {
    initDraggable,
    removeDraggable,
    getOpenedChatboxPosition,
    getIsDraggableEnabled,
    setIsDraggableEnabled,
    getIsDragging,
  };
};

export type Draggable = typeof draggable;
export type DraggableInstance = ReturnType<typeof draggable>;
