export const messageTypeEnum = {
  DRAG_START: 'idz-iframe-drag-start',
  DRAG_MOVE: 'idz-iframe-drag-move',
  DRAG_END: 'idz-iframe-drag-end',
  RESIZE: 'idz-iframe-resize',
} as const;

export const postMessageFactory = (parentNode: Window) =>
  (message: string) => parentNode.postMessage(message, '*');

export default messageTypeEnum;
