import { getStaticUrl } from '../../modules/shared/staticUrl';
import { getIsSentryLoaded } from '../../modules/shared/utils/sentry/sentryLauncher';
import { messageTypeEnum } from '../postMessageWrapper';
import scheduleOnMainJsThread from '../scheduleOnMainTread';

export const iframes: { [key: string]: HTMLIFrameElement } = {};

/*________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/

export const appendSkeleton = (
  targetDocument: Document,
  appContainerId: string,
  lang: string,
) => {
  //_______________________________________________
  targetDocument.documentElement.lang = lang;
  //___________________________________________________
  const link = targetDocument.createElement('link');
  link.rel = 'icon';
  link.href = 'data:;base64,iVBORw0KGgo=';
  const meta = targetDocument.createElement('meta');
  meta.name = 'robots';
  meta.content = 'none';
  targetDocument.head.appendChild(link);
  targetDocument.head.appendChild(meta);
  const rootDiv = targetDocument.createElement('div');
  rootDiv.id = appContainerId;
  targetDocument.body.appendChild(rootDiv);
};
/*_______________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
export const addScript = (
  targetDocument: Document,
  src: string,
  defer = false,
) => {
  const script = targetDocument.createElement('script');
  script.setAttribute('charset', 'utf-8');
  script.setAttribute('type', 'text/javascript');
  script.src = src;
  script.defer = defer;

  targetDocument.head.appendChild(script);
  return targetDocument;
};

/*_______________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
export const addStylesheet = (targetDocument: Document, src: string) => {
  const link = targetDocument.createElement('link');
  link.href = src;
  link.setAttribute('type', 'text/css');
  link.setAttribute('rel', 'stylesheet');

  targetDocument.head.appendChild(link);
  return targetDocument;
};

/*______________________________________________________________________________________________________________________________________________________________________________________________________________*/
export const createIframe = (iframeId: string, title = '') => {
  const iframe = document.createElement('iframe');
  iframe.setAttribute('id', iframeId);
  iframe.setAttribute('allowfullscreen', '');
  iframe.setAttribute('seamless', '');
  iframe.setAttribute('frameborder', '0');
  iframe.setAttribute('scrolling', 'no');
  iframe.setAttribute('title', title);

  return iframe;
};

/*________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
export const addOrReplaceIframe = (
  mountingElement: HTMLElement,
  objectsToAttach: { [key: string]: unknown },
  iframeId: string,
  appContainerId: string,
  lang: string,
  iframeStyle: Partial<CSSStyleDeclaration> = {},
  scripts: string[] = [],
  stylesheets: string[] = [],
  title = '',
): HTMLIFrameElement => {
  const iframe = createIframe(iframeId, title);

  const isIframeLoaded = (
    maybeiframeWindow: Window | null,
  ): maybeiframeWindow is Window => maybeiframeWindow !== null;

  const loadIframe = (iframeWindow: Window) => {
    const iframeDocument = iframeWindow.document;
    appendSkeleton(iframeDocument, appContainerId, lang);

    Object.assign(iframeWindow, { iframeId });

    //___________________
    if (getIsSentryLoaded()) {
      addScript(
        iframeDocument,
        `${getStaticUrl()}sentryIframeLauncher.js`,
        true,
      );
    }

    scripts.forEach((script) => {
      addScript(iframeDocument, script);
    });

    stylesheets.forEach((stylesheet) => {
      addStylesheet(iframeDocument, stylesheet);
    });

    Object.assign(iframeWindow, objectsToAttach);
  };

  //_________________________________________
  //___________________________________________________________________________________
  if (isIframeLoaded(iframe.contentWindow)) {
    loadIframe(iframe.contentWindow);
  } else {
    iframe.onload = () => {
      if (isIframeLoaded(iframe.contentWindow)) {
        loadIframe(iframe.contentWindow);
      }
    };
  }
  mountingElement.appendChild(iframe);

  Object.assign(iframe.style, iframeStyle);

  iframes[iframeId] = iframe;

  return iframe;
};

/*____________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
export const addIfDoesNotExistIframe = (
  mountingElement: HTMLElement | null,
  objectsToAttach: { [key: string]: unknown },
  iframeId: string,
  appContainerId: string,
  lang: string,
  iframeStyle: Partial<CSSStyleDeclaration> = {},
  scripts: string[] = [],
  stylesheets: string[] = [],
  title = '',
): void => {
  const existingIframe = iframes[iframeId];
  if (existingIframe) return;
  if (!mountingElement) return;

  addOrReplaceIframe(
    mountingElement,
    objectsToAttach,
    iframeId,
    appContainerId,
    lang,
    iframeStyle,
    scripts,
    stylesheets,
    title,
  );
};

/*______________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
export const addOrReplaceHiddenIframe = (
  mountingElement: HTMLElement,
  objectsToAttach: { [key: string]: unknown },
  iframeId: string,
  appContainerId: string,
  lang: string,
  scripts: string[] = [],
) => {
  const iFrameHiddenStyle: Partial<CSSStyleDeclaration> = {
    display: 'none',
    width: '1px',
    height: '1px',
    border: '0',
  } as const;

  const translations = {
    fr: 'Encadré sans contenu',
    en: 'Box without content',
    es: 'iframe sin contenido',
    de: 'iframe ohne Inhalt',
  } as const;

  type KnownTranslations = 'fr' | 'en' | 'es' | 'de';

  const isKnownKey = (t: string): t is KnownTranslations =>
    Object.keys(translations).indexOf(t) !== -1;

  const getTranslationTitle = (t: string) =>
    isKnownKey(t) ? translations[t] : translations.en;

  scheduleOnMainJsThread(() => {
    const iframe = addOrReplaceIframe(
      mountingElement,
      objectsToAttach,
      iframeId,
      appContainerId,
      lang,
      iFrameHiddenStyle,
      scripts,
      [],
      getTranslationTitle(lang),
    );

    iframe.setAttribute('aria-hidden', 'true');
  })();
};

/*______________________________________________________*/
export const unmountRootNodeFromIframe = (
  iframeId: string,
  rootNodeSelector: string,
) => {
  const iframe = iframes[iframeId];
  const rootNode =
    iframe?.contentWindow?.document.getElementById(rootNodeSelector);
  if (rootNode) {
    rootNode.dispatchEvent(new CustomEvent('unmount'));
  }
};

/*____________________________________________________________________________________________________________________________*/
export const destroyIframeByID = (iframeId: string) => {
  const iframe = iframes[iframeId];
  if (iframe?.parentNode) {
    iframe.parentNode.removeChild(iframe);
    delete iframes[iframeId];
  }
};

//_________________________
window.addEventListener(
  'message',
  (event) => {
    if (event.data.type === messageTypeEnum.RESIZE) {
      const iframe = document.getElementById(event.data.payload.iframeId);
      if (iframe) {
        const { width, height } = event.data.payload.size;
        iframe.style.width = `${width}px`;
        iframe.style.height = `${height}px`;
      }
    }
  },
  false,
);
